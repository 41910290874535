import { generatePath } from 'react-router';
import { get, isNil } from 'lodash';
import withUseAsyncLoading from 'utilities/with-loading/withUseAsyncLoading';
import withUseLoading from 'utilities/with-loading/withUseLoading';
import { MAX_NO_OF_INVOICES_FOR_SINGLE_INSTALLMENT_DEFAULT } from 'constants/invoice.constant';
import { hesiActions } from 'modules/hesi/redux/hesi';
import { MAX_NO_OF_PROGRAMS_FOR_HESI_EXAM_PYMT_ORDER } from 'modules/hesi/constants/exam-order.constant';
import { DEFAULT_ASSESS_ROLES } from '../../constants/app.constant';
import { appActions } from './app.reducer';
import { appSelectors } from '../app';
import { evolveServices } from '../../services';
import {
  cohortManagementDashboardBreadcrumb,
  inFlightDashboardBreadcrumb
} from '../../utilities/breadcrumb/breadcrumb.utility';

type BreadcrumbItem = {
  path?: {
    route: string;
    params?: {
      [key: string]: string | number | boolean;
    }
  },
  text: string;
};

const generateBreadcrumbs = (items: BreadcrumbItem[]) => {
  const breadcrumbs = [];
  const { length } = items;
  for (let i = 0; i < length; i += 1) {
    breadcrumbs.push({
      path: (items[i].path) ? generatePath(items[i].path.route, items[i].path.params) : null,
      text: items[i].text
    });
  }
  return breadcrumbs;
};

export const setBreadcrumbs = (...items: BreadcrumbItem[]) => async (dispatch) => {
  const breadcrumbs = generateBreadcrumbs(items);
  dispatch(appActions.setBreadcrumbs(breadcrumbs));
};

export const setBreadcrumbsForDashboard = (...items: BreadcrumbItem[]) => async (dispatch, getState) => {
  const breadcrumbsItems: BreadcrumbItem[] = [cohortManagementDashboardBreadcrumb()];
  if (appSelectors.getInflightCohortView(getState())) {
    breadcrumbsItems.push(inFlightDashboardBreadcrumb());
  }
  if (items && items.length > 0) {
    breadcrumbsItems.push(...items);
  }

  if (breadcrumbsItems.length === 1) {
    dispatch(appActions.setBreadcrumbs([]));
  } else {
    const breadcrumbs = generateBreadcrumbs(breadcrumbsItems);
    dispatch(appActions.setBreadcrumbs(breadcrumbs));
  }
};

export const fetchEvolveNotifications = (roleId, isAuthenticated) =>
  withUseAsyncLoading('fetchEvolveNotifications')(async (dispatch) => {
    const notificationPromise = async () => {
      try {
        return await evolveServices.fetchNotifications(roleId, isAuthenticated);
      } catch (err) {
        return [];
      }
    };
    const notifications = await notificationPromise();
    dispatch(appActions.setNotifications({ notifications }));
  });

export const fetchAppConfig = () =>
  withUseLoading(async (dispatch) => {
    const response = await evolveServices.fetchAppConfig();
    const accessRolesString = get(response, 'UI_Portal.UI_TSP_ACCESS_ROLES');
    let accessRoles = !isNil(accessRolesString) ? accessRolesString.split(',') : DEFAULT_ASSESS_ROLES;
    accessRoles = accessRoles.map(r => r && r.trim());
    const isTspEnabled = get(response, 'UI_Portal.UI_TSP_ENABLED') || false;
    const enableHesiIntegrationPhase1 = get(response, 'UI_Portal.UI_TSP_ENABLE_HESI_INTEGRATION_PHASE1') || false;
    const enableStudentGroups = get(response, 'UI_Portal.UI_TSP_ENABLE_STUDENT_GROUPS') || false;
    const maxInvoicesNumber = get(response, 'UI_Portal.UI_MAX_NO_OF_INVOICES_FOR_SINGLE_INSTALLMENT') || MAX_NO_OF_INVOICES_FOR_SINGLE_INSTALLMENT_DEFAULT;
    const maxProgramsNumber = get(response, 'UI_Portal.UI_MAX_NO_OF_PROGRAMS_FOR_HESI_EXAM_PYMT_ORDER') || MAX_NO_OF_PROGRAMS_FOR_HESI_EXAM_PYMT_ORDER;
    dispatch(appActions.setAppConfig({ accessRoles, isTspEnabled, enableHesiIntegrationPhase1, enableStudentGroups, maxInvoicesNumber }));
    dispatch(hesiActions.setHesiConfig({ maxProgramsNumber }));
    dispatch(hesiActions.setHesiAccessRoles(get(response, 'UI_Portal.UI_HESI_ACCESS_ROLES')));
  });

let messageTimeout;
export const setMessage = (success: boolean, text: string) => dispatch => {
  dispatch(appActions.setMessage({ success, text }));
  clearTimeout(messageTimeout);
  messageTimeout = setTimeout(() => {
    dispatch(appActions.resetMessage());
  }, 5000);
};
