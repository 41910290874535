export const EXAM_TO_CART_POPUP_TYPE = {
  EXAM_PROGRAM: 0,
  EXAM_ASSIGN_PERMISSION: 1,
  EXAM_SELECT_QUANTITY: 2
};

export const assessmentTypeFields = {
  assessmentTypeId: 'assessmentTypeId',
  assessmentTypeName: 'assessmentTypeName',
};

export const programTypeFields = {
  programTypeId: 'programTypeId',
  programTypeName: 'programTypeName'
};

export const PAYMENT_METHODS = {
  CREDIT_CARD: 'CREDIT_CARD',
  BILL_MY_INSTITUTION: 'BILL_MY_INSTITUTION',
  BILL_EACH_STUDENT: 'BILL_EACH_STUDENT'
};

export const PAYMENT_METHOD_NAMES = {
  CREDIT_CARD: 'Credit card',
  BILL_MY_INSTITUTION: 'Bill my institution',
  BILL_EACH_STUDENT: 'Bill each student'
};

export const PAYMENT_METHOD_OPTIONS = [
  { name: 'Select a payment method', value: '' },
  { name: PAYMENT_METHOD_NAMES.CREDIT_CARD, value: PAYMENT_METHODS.CREDIT_CARD },
  { name: PAYMENT_METHOD_NAMES.BILL_MY_INSTITUTION, value: PAYMENT_METHODS.BILL_MY_INSTITUTION },
  { name: PAYMENT_METHOD_NAMES.BILL_EACH_STUDENT, value: PAYMENT_METHODS.BILL_EACH_STUDENT }
];

export const INPUT_QUANTITY_PATTERN = /^\d+(\.\d{0,2})?$/;

export const HESI_CART_TYPES = {
  CATALOG: 'CATALOG',
  PAYMENT: 'PAYMENT',
};

export const LIMIT_DISPLAY_FACULTIES = 4;
export const LIMIT_DISPLAY_PROGRAMS = 4;

export const ORDER_CART_TABS = {
  REVIEW: 0,
  PAYMENT: 1,
  CONFIRM: 2
};

export const PHONE_ERROR_MSGS = {
  INVALID_NUMBER: 'The Contact Phone you entered is invalid.',
  REQUIRED: 'Contact Phone is required.'
};

export enum PaymentInformationFields {
  orderingFacultyContactFirstName = 'orderingFacultyContactFirstName',
  orderingFacultyContactLastName = 'orderingFacultyContactLastName',
  orderingFacultyEmail = 'orderingFacultyEmail',
  orderingFacultyContactPhone = 'orderingFacultyContactPhone',
  orderingFacultyContactPhoneExtension = 'orderingFacultyContactPhoneExtension',
  billingAddress = 'billingAddress',
  secondaryContactFullName = 'secondaryContactFullName',
  secondaryContactTitle = 'secondaryContactTitle',
  secondaryContactEmail = 'secondaryContactEmail',
  programExamOrderItems = 'programExamOrderItems'
}

export const defaultPaymentInformationState = {
  orderingFacultyContactFirstName: '',
  orderingFacultyContactLastName: '',
  orderingFacultyEmail: '',
  orderingFacultyContactPhone: '',
  orderingFacultyContactPhoneExtension: '',
  billingAddress: {
    address1: '',
    address2: '',
    country: '',
    state: '',
    city: '',
    postalCode: ''
  },
  secondaryContactFullName: '',
  secondaryContactTitle: '',
  secondaryContactEmail: ''
};

export enum TSP_HESI_EXAM_ORDER_SENT_BOM_ERROR_TYPES {
  SENT_BOM_FAILED = 'tsp_hesi_exam_order_sent_bom_failed'
}
export const ExamOrderSearchDateRangeText = 'Search by order date range';
export const ExamOrderFilterStatusText = 'Search by order details:';
export const ExamOrderDateRangeFields = {
  fromDate: 'fromDate',
  toDate: 'toDate'
};

export const DEFAULT_ERROR_MESSAGE = 'An unexpected issue occurred. Please try submitting your order again. My order is not submitted.';
export const NO_PROGRAMS_MESSAGE = 'Please select other exam, as this selected exam does not have any programs available for ordering.';

export const ExamOrderStatuses = {
  Pending: { id: 59, name: 'Pending' },
  Open: { id: 60, name: 'Open' },
  Failed: { id: 61, name: 'Failed' },
  Closed: { id: 62, name: 'Closed' }
};

export const RequestAddressChangesText = 'Request Address Change(s)';

export const REQUEST_ADDRESS_CHANGE_POPUP_TYPE = {
  ADDRESS_CHANGE_EDITOR: 0,
  ADDRESS_CHANGE_CONFIRM: 1,
  ADDRESS_CHANGE_NOTIFY: 2,
};

export enum PaymentAddressFields {
  billingAddress = 'billingAddress',
  shippingAddress = 'shippingAddress',
}

export const MAX_NO_OF_PROGRAMS_FOR_HESI_EXAM_PYMT_ORDER = 3;
