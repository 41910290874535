import { get, isEmpty, some } from 'lodash';
import { ALCConstants } from 'modules/hesi/constants/hesi.constants';
import { securitySelectors } from 'redux/security';

export const REDUCER_ID = ALCConstants.redux.HESI_STATE;

export const getHesiAccessRoles = globalState => get(globalState, `${REDUCER_ID}.accessRoles`, []);
export const getExamOrderRequestPayload = (initialState) => globalState => {
  if (globalState[REDUCER_ID].examOrderRequestPayload) {
    return globalState[REDUCER_ID].examOrderRequestPayload;
  }
  return initialState;
};

export const isHesiAuthorized = (allowRoles = []) => globalState => {
  const accessRoles = getHesiAccessRoles(globalState);
  const allowAccessRoles = isEmpty(allowRoles) ? accessRoles : allowRoles;
  const userRoles = securitySelectors.getRoles(globalState);
  if (isEmpty(allowAccessRoles) || isEmpty(userRoles)) {
    return false;
  }
  return some(userRoles, role => allowAccessRoles.includes(role));
};

export const maxProgramsNumber = (globalState) => globalState[REDUCER_ID].maxProgramsNumber;
