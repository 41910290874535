import moment from 'moment';
import momentTimeZone from 'moment-timezone';
import { TIMEZONE_AMERICA_CHICAGO, DATE_FORMAT_MDYYYY, DATE_TIME_FORMAT_MDYYYYHMMSSA } from 'constants/app.constant';

/**
 * this function supports to change a date with local timezone to UTC timezone without datetime conversion
 * @param localDate date is a local date time
 */
export const changeDateToISODate = (localDate: Date): Date => {
  const dateStr = moment(localDate).format(DATE_FORMAT_MDYYYY);
  return moment.utc(dateStr).toDate();
};

/**
 * this function supports to change a date with UTC timezone to local timezone without datetime conversion
 * @param isoDate date is a iso date time
 */
export const changeDateToLocalDate = (isoDate: Date | string): Date => {
  const dateStr = moment.utc(isoDate).format(DATE_FORMAT_MDYYYY);
  return moment(dateStr).toDate();
};

/**
 * this function supports to change a date string to UTC timezone without datetime conversion
 * @param isoDate
 */
export const parseISODate = (isoDate: string): Date => moment.utc(isoDate).toDate();

/**
 * keep the date time in CST timezone and convert it to GMT timezone
 * @param dateStr: a date string with format 'MM/DD/YYYY HH:mm:ss A'
 * @returns
 */
export const convertCSTToGMT = (dateStr: string, formatDate: string) => {
  const cstDate = momentTimeZone.tz(dateStr, formatDate, TIMEZONE_AMERICA_CHICAGO);
  return cstDate.utc().toISOString();
};

export const formatCSTDateToISOString = (str: string | Date) => {
  const dateStr = moment(str).startOf('day').format(DATE_TIME_FORMAT_MDYYYYHMMSSA);
  return convertCSTToGMT(dateStr, DATE_TIME_FORMAT_MDYYYYHMMSSA);
};

export const formatCSTDateWithEndDateToISOString = (str: string | Date) => {
  const dateStr = moment(str).endOf('day').format(DATE_TIME_FORMAT_MDYYYYHMMSSA);
  return convertCSTToGMT(dateStr, DATE_TIME_FORMAT_MDYYYYHMMSSA);
};
