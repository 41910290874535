import { PagingOptions } from '../../../models/api.model';
import { ExamOrderModel, ExamOrderDashboardFilterParamsModel, ExamOrderItemModel, ExamPaymentOrder, ExamPaymentOrderStatus } from '../models/examOrder.model';
import { buildEvolvePagingOptions } from '../../../helpers/api.helpers';
import { tspServiceApi } from '../../../helpers/RestClient.helper';

export const getExamOrderDashboard = (pagingOptions: PagingOptions, requestParam: ExamOrderDashboardFilterParamsModel) => {
  const data = {
    ...buildEvolvePagingOptions(pagingOptions),
    ...requestParam
  };
  return tspServiceApi.post('/hesi/alc/admin/examOrders/search', { data }) as Promise<{
    examOrders: ExamOrderModel[];
    totalCount: number;
    startIndex?: number;
    size?: number;
  }>;
};

export const getExamOrderDetails = (hesiExamOrderId: string) => tspServiceApi.get(`/hesi/alc/admin/examOrders/${hesiExamOrderId}`) as Promise<ExamOrderModel>;

export const getExamOrderItemsByExamOrderId = (hesiExamOrderId: string) =>
  tspServiceApi.get(`/hesi/alc/admin/examOrders/${hesiExamOrderId}/examOrderItems`) as Promise<{
    examOrderId: string;
    examOrderItems: ExamOrderItemModel[];
  }>;

export const getExamOrderPaymentsByExamOrderId = (hesiExamOrderId: string) =>
  tspServiceApi.get(`/hesi/alc/admin/examOrders/${hesiExamOrderId}/examPymtOrders`) as Promise<{ examOrderId: string; examPymtOrders: ExamPaymentOrder[] }>;

export const fetchHesiInvoiceStatusHistory = (tspHesiInvoiceId: number) =>
  tspServiceApi.get(`/hesi/alc/admin/examPymtOrders/invoices/${tspHesiInvoiceId}/statusHistory`) as Promise<{
    tspHesiInvoiceId: number;
    statusHistory: ExamPaymentOrderStatus[];
  }>;

export const fetchHesiEcomPaymentStatusHistory = (tspHesiEcomPaymentId: number) =>
  tspServiceApi.get(`/hesi/alc/admin/examPymtOrders/ecomPayments/${tspHesiEcomPaymentId}/statusHistory`) as Promise<{
    tspHesiEcomPaymentId: number;
    statusHistory: ExamPaymentOrderStatus[];
  }>;
